import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";

import productivity from "../images/palmomedia-productivity.jpg";
import webseiten from "../images/palmomedia-webseiten.jpg";

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Webseiten Gestaltung und Programmierung" />
      <h1>Webseiten</h1>
      <p>
        Sie möchten keine 50.000 Euro für eine "einfache" Webseite bezahlen? Ein
        bisschen mehr als Jimdo sollte es aber schon sein? Dann sind Sie bei uns
        genau richtig. Mittels JAMStack und dem CMS Ihrer Wahl können wir
        bereits morgen durchstarten.
      </p>
      <p>
        Wir gestalten und entwickeln ihre neue Webseite nach den aktuellen
        Standards im Internet. Wir verstehen ihr Produkt und bauen zusammen mit
        Ihnen, Ihre <b>maßgeschneiderte Webseite</b>.
      </p>
      <img src={productivity} alt="productivity" className="images " />
      <br />
      <br />
      <p>
        Durch den modernen Ansatz des sogenannten <b>JAMStacks</b>...
      </p>
      <h2>JAMStack</h2>
      <p>
        „JAMSTACK IS REVOLUTIONISING THE WAY WE THINK ABOUT WORKFLOW BY
        PROVIDING A SIMPLER DEVELOPER EXPERIENCE, BETTER PERFORMANCE, LOWER COST
        AND GREATER SCALABILITY.”{" "}
        <a
          href="HTTPS://JAMSTACK.WTF/"
          rel="noreferrer"
          target="_blank"
          title="JAMStack"
        >
          JAMStack.wtf
        </a>
      </p>
      <p>
        ...(Javascript, API und Markup) können wir schnell und leichtgewichtig
        ihr Ziel erreichen. Sie geben uns die Randparameter: Mehrsprachigkeit,
        Content Management System, Zahl der Unterseiten, Design… und wir zeigen
        Ihnen, dass es auch <u>OHNE</u> <b>Wordpress</b>, <b>Wix</b> oder{" "}
        <b>Jimdo</b> zu einem schnellen und vor allem nachhaltigen und
        zielgerichteten Ergebnis kommen wird.
      </p>
      <img src={webseiten} alt="webseiten" className="images" />
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
